import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Doodle #6",
  "featuredImage": "images/love+drops+v1.jpg",
  "coverAlt": "Doodle 6",
  "description": "Doodle - Love Drops",
  "date": "2017-03-28",
  "dateModified": "2017-03-28",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Doodles", "Graphic Design"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Doodle! First draft of “Love Drops” - `}<strong parentName="p"><a parentName="strong" {...{
          "href": "http://www.tonyebrown.com/2017/03/doodle-3.html"
        }}>{`See the final version here`}</a></strong>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "images/love%2Bdrops%2Bv1.jpg",
        "alt": "Love drops"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      